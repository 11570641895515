.gt-broken {
    padding: 2em;
    border: none;
}

.gt-broken__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem
}

.gt-broken__logo {
    margin: 2em;
    opacity: 0.5;
    max-width: 400px;
    width: 30vw;
}
