.gt-mode {
  width: 100%;
  height: 100%;
}

.gt-mode__menu-bar {
  display: flex;
  flex-direction: row;
  padding: 1rem 5px;
}

.gt-mode__container {
  min-height: 200px;
  position: relative;
}

.gt-mode__loader {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(243, 243, 243, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gt-mode__embed {
  border: 0;
}
