.gt-select {
    padding-top: 0.5em;
    width: 100%;
    display: flex;
    align-items: center;
}

.gt-select__input {
    width: calc(100% - 42px);
}

.gt-select__clear {
    width: 42px;
    height: 42px;
}
